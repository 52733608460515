<script>
import useVuelidate from "@vuelidate/core";
import {
  required, email,
  minLength, maxLength,
  helpers,
} from "@vuelidate/validators";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      teachers: [],
      teacher: {},
      data: {
        role: "tutor",
      },
      page: 1,
      perPage: 5,
      pages: [],
      submited: false,
      spanView: false,
      title: "Nouveau Tuteur",
      items: [
        {
          text: "Tuteurs",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      nom: {
        required: helpers.withMessage("Le champ nom est requis", required),
        minLength: helpers.withMessage("2 caractères minimum", minLength(2)),
        maxLength: helpers.withMessage("20 caractères maximum", maxLength(20)),
      },
      prenom: {
        required: helpers.withMessage("Le champ prénom est requis", required),
        minLength: helpers.withMessage("2 caractères minimum", minLength(2)),
        maxLength: helpers.withMessage("20 caractères maximum", maxLength(20)),
      },
      numero_de_telephone: {
        minLength: helpers.withMessage("8 caractères minimum", minLength(8)),
        required: helpers.withMessage("Veuillez entrer le numéro", required),
      },
      email: {
        required: helpers.withMessage("Le champ email est requis", required),
        email: helpers.withMessage("Veuillez entrer un email valide", email),
      },
      sexe: {
        required: helpers.withMessage("Le champ genre est requis", required),
      },
      domicile: {
        minLength: helpers.withMessage("2 caractères minimum", minLength(2)),
        maxLength: helpers.withMessage("30 caractères maximum", maxLength(30)),
      },
      profession: {
        required: helpers.withMessage("Veuillez Entrer la profession", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueTelInput,
  },
  mounted() {
    this.getAllTutors();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.teachers);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.id.toLowerCase().includes(search) ||
            data.name.toLowerCase().includes(search) ||
            data.email.toLowerCase().includes(search) ||
            data.position.toLowerCase().includes(search) ||
            data.company.toLowerCase().includes(search) ||
            data.country.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    initCreation() {
      this.submited = true;
      this.createTeacher();
    },
    createTeacher() {
      this.v$.data.$touch();
      const self = this;
      this.spanView = true;
      this.$store
        .dispatch("postRequest", { route: "/api/user", data: this.data })
        .then(
          function (response) {
            self.getAllTutors();
            self.v$.data.$reset();
            self.spanView = false;
            self.data = {};
            Swal.fire(response.data.message, "Etudiant enrégistré!", "success");
          },
          function (error) {
            self.spanView = false;
            if (error !== null) {
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },
    getTeacher(teacher) {
      this.teacher = teacher;
    },
    getAllTutors() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/tutor",
          data: this.data,
        })
        .then(
          function (response) {
            self.teachers = response.data.searchedUsers;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },
    navToTutorStudents(id){
      this.$router.push({ path: `/tutor/students/${id}` })
    },
    setPages() {
      let numberOfPages = Math.ceil(this.teachers.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>

<style scoped>
#number {
  height: 39px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" placeholder="Search..." />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Tuteur
              </button>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" data-sort="nom" scope="col">
                    Nom
                  </th>
                  <th class="sort" data-sort="prenom" scope="col">
                    Prenom
                  </th>
                  <th class="sort" data-sort="mail" scope="col">Email</th>
                  <th class="sort" data-sort="phone" scope="col">Téléphone</th>
                  <th class="sort" data-sort="sexe" scope="col">Sexe</th>
                  <th class="sort" data-sort="profession" scope="col"> Profession </th>
                  <th class="sort" data-sort="high" scope="col">Satut</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td>{{ data.nom }}</td>
                  <td>{{ data.prenom }}</td>
                  <td class="pairs">{{ data.email }}</td>
                  <td class="pairs">{{ data.numero_de_telephone }}</td>
                  <td>{{ data.sexe.toUpperCase() }}</td>
                  <td>{{ data.profession }}</td>
                  <td>
                    <span
                      class="badge font-size-11 m-1"
                      :class="{
                        'bg-success': `${data.status}` === 'active',
                        'bg-warning': `${data.status}` === 'waiting',
                        'bg-danger': `${data.status}` === 'inactive',
                      }"
                      >{{ data.status }}</span
                    >
                  </td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalgrid"
                        @click="getTeacher(data)"
                        class="ml-3 link-primary fs-15"
                        ><i class="ri-eye-line"></i
                      ></a>
                      <a href="javascript:void(0);" class="link-warning fs-15"
                        ><i class="ri-edit-2-line"></i
                      ></a>
                      <a href="javascript:void(0);" @click="navToTutorStudents(data.id)" class="link-success fs-15"
                        ><i class="ri-user-2-line"></i
                      ></a>
                      <a href="javascript:void(0);" class="link-danger fs-15"
                        ><i class="ri-delete-bin-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
        </div>
        <div
            class="modal fade"
            id="exampleModalgrid"
            tabindex="-1"
            aria-labelledby="exampleModalgridLabel"
            aria-modal="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body card">
                  <div class="card-body">
                    <div class="text-center mb-2">
                      <div
                        class="profile-user position-relative d-inline-block mx-auto mb-4"
                      >
                        <img
                          src="@/assets/images/users/avatar-1.jpg"
                          class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile-image"
                        />
                      </div>
                      <h5 class="fs-16 mb-1">
                        {{ this.teacher.prenom }} {{ this.teacher.nom }}
                      </h5>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <th class="ps-0" scope="row">Matricule :</th>
                            <td class="text-muted">
                              {{ this.teacher.matricule }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">Téléphone :</th>
                            <td class="text-muted">
                              {{ this.teacher.numero_de_telephone }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">E-mail :</th>
                            <td class="text-muted">
                              {{ this.teacher.email }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">Sexe :</th>
                            <td class="text-muted">
                              {{ this.teacher.sexe }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">Adresse :</th>
                            <td class="text-muted">
                              {{ this.teacher.domicile }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">
                              Profession :
                            </th>
                            <td class="text-muted">
                              {{ this.teacher.profession }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">
                              Adresse :
                            </th>
                            <td class="text-muted">
                              {{ this.teacher.domicile }}
                            </td>
                          </tr>

                          <tr v-if="teacher.status === 'waiting'">
                            <th class="ps-0" scope="row">
                              Lien de confirmation:
                            </th>
                            <td>
                              <a
                                :href="
                                  'http://localhost:8080/account-confirm/' +
                                  teacher.code
                                "
                                class="text-muted"
                              >
                                LIEN 
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Large modal
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstname" class="form-label">Nom</label>
                        <input
                          type="text"
                          class="form-control"
                          id="firstname"
                          v-model="data.nom"
                          :class="{
                            'is-invalid':
                              (v$.data.nom.$error && data.nom) ||
                              (v$.data.nom.$error && submited),
                          }"
                          placeholder="Nom de l'étudiant "
                        />
                        <div
                          v-for="(item, index) in v$.data.nom.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.nom) ||
                              (v$.data.nom.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastname" class="form-label">Prénom</label>
                        <input
                          type="text"
                          class="form-control"
                          id="lastname"
                          v-model="data.prenom"
                          :class="{
                            'is-invalid':
                              (v$.data.prenom.$error && data.prenom) ||
                              (v$.data.prenom.$error && submited),
                          }"
                          placeholder="Prénom de l'étudiant"
                        />
                        <div
                          v-for="(item, index) in v$.data.prenom.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.prenom) ||
                              (v$.data.prenom.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="numero_de_telephone" class="form-label"
                          >Numéro</label
                        >
                        <vue-tel-input
                          class="form-control"
                          v-model="data.numero_de_telephone"
                          id="numero_de_telephone"
                          :class="{
                            'is-invalid':
                              (v$.data.numero_de_telephone.$error && data.numero_de_telephone) ||
                              (v$.data.numero_de_telephone.$error && submited),
                          }"
                          mode="international"
                          placeholder="Numero de téléphone de l'étudiant"
                        ></vue-tel-input>
                        <div
                          v-for="(item, index) in v$.data.numero_de_telephone
                            .$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.numero_de_telephone) ||
                              (v$.data.numero_de_telephone.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          v-model="data.email"
                          :class="{
                            'is-invalid':
                              (v$.data.email.$error && data.email) ||
                              (v$.data.email.$error && submited),
                          }"
                          placeholder="Email de l'étudiant"
                        />
                        <div
                          v-for="(item, index) in v$.data.email.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.email) ||
                              (v$.data.email.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="sexe" class="form-label">Genre</label>
                        <Multiselect
                          class="form-control"
                          v-model="data.sexe"
                          :class="{
                            'is-invalid':
                              (v$.data.sexe.$error && data.sexe) ||
                              (v$.data.sexe.$error && submited),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="[
                            { value: 'Masculin', label: 'Masculin' },
                            { value: 'Feminin', label: 'Feminin' },
                          ]"
                        />
                        <div
                          v-for="(item, index) in v$.data.sexe.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.sexe) ||
                              (v$.data.sexe.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Address</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="designationInput"
                          v-model="data.domicile"
                          :class="{
                            'is-invalid':
                              (v$.data.domicile.$error && data.domicile) ||
                              (v$.data.domicile.$error && submited),
                          }"
                          placeholder="Adresse"
                        />
                        <div
                          v-for="(item, index) in v$.data.domicile.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.domicile) ||
                              (v$.data.domicile.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Profession</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="designationInput"
                          v-model="data.profession"
                          :class="{
                            'is-invalid':
                              (v$.data.profession.$error && data.profession) ||
                              (v$.data.profession.$error && submited),
                          }"
                          placeholder="Profession"
                        />
                        <div
                          v-for="(item, index) in v$.data.profession.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.profession) ||
                              (v$.data.profession.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
